import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';


export interface IEndpoint {
  getAll(): unknown;
  get(id: string): unknown;
  delete(id: string): unknown;
  update(id: String, model: {}): unknown;
}

function entries<T extends { [K in keyof T]: string }>(obj: T): Array<[keyof T, string]> {
  return Object.entries(obj) as Array<[keyof T, string]>;
}

type ExtractParams<S extends string> =
  S extends `${infer _Start}{${infer Param}}${infer Rest}`
    ? Param | ExtractParams<Rest>
    : never;

export class BaseEndpointService<RouteTemplate extends string> {
  protected endpointTemplate: string;
  protected endpoint: string;
  protected readonly http: HttpClient = inject(HttpClient);

  constructor(options: { routeTemplate: RouteTemplate }) {
    this.endpointTemplate = options.routeTemplate;
    this.endpoint = environment.apiUrl + options.routeTemplate;
  }


  setResourceParams(
    params: { [K in ExtractParams<RouteTemplate>]: string }
  ) {
    this.endpoint = environment.apiUrl + this.endpointTemplate;

    for (const [key, value] of entries(params)) {
      this.endpoint = this.endpoint.replace(
        `{${key}}`,
        encodeURIComponent(value)
      );
    }
  }
}
